import { FC } from "react";
import { OurClients } from "./shared/OurClients";
import { SectionCardContent } from "./shared/SectionCardHeader";
import { classNames } from "../utilities/helpers";

export const TwoColCard: FC<{ dark: boolean }> = ({ dark }) => {
  return (
    <div
      id="about-us"
      className={classNames(dark ? "bg-black" : "bg-white ")}
    >
      <div className="pt-24">
        <div className="grid gap-8 px-6 mx-auto md:pl-8 md:grid-cols-2 max-w-7xl">
          <SectionCardContent
            title="We work with you to succeed."
            header="About Us"
            copy="At Quetzal Consults, we collaborate closely with you to turn your vision into 
            powerful solutions. Our dedicated team thrives on challenges, ensuring shared success 
            through innovation and tailored expertise."
            // buttonProps={{
            //   label: "Learn More",
            //   destination: "",
            //   type: ButtonTypes.secondary,
            // }}
            dark={dark}
          />

          <img
            src="img1.jpg"
            alt=""
          />
        </div>
        <OurClients />
      </div>
    </div>
  );
};
