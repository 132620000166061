import { ButtonTypes, MainButton } from "../MainButtons";

import { FC } from "react";
import { urls } from "../../routes/RouteDefinitions";

export const OurClients = () => {
  return (
    <div className="py-24 bg-white sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="space-y-6">
          <div className="w-full mx-auto text-center lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Building Success. Together
            </h2>
            <p className="mt-6 leading-8 text-gray-600 text-md">
              Our customer philosophy revolves around one simple principle:
              empowering you every step of the way. We are committed to
              providing exceptional support, guidance, and resources to ensure
              that you achieve success in a collaborative way!
            </p>
          </div>
          <div className="-mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-4 place-items-stretch">
            <ImgHolder
              src="./ril.svg"
              alt="Resource Intermediaries Limited"
            />
            <ImgHolder
              src="./alphamorgan.svg"
              alt="Alpha Morgan"
            />
            <ImgHolder
              src="./hbc.svg"
              alt="Heritage Bank"
            />
            <ImgHolder
              src="./hiosl.svg"
              alt="Homeland"
            />
            <ImgHolder
              src="./egbin.svg"
              alt="Egbin"
            />
            <ImgHolder
              src="./tsl_new.png"
              alt="TSL"
            />
            <ImgHolder
              src="./newcross_p.png"
              alt="Newcross Petroleum"
            />
            <ImgHolder
              src="./panocean.png"
              alt="Pan Ocean"
            />
            <ImgHolder
              src="./grandoak_new.png"
              alt="Grand Oak"
              width={180}
            />
            <ImgHolder
              src="./newcross_exploration.png"
              alt="Newcross Exploration"
            />
          </div>
          {/* <p className="w-full font-semibold text-center text-gray-700">
            ...and more
          </p> */}
          {/* <div className="flex items-center mt-8 gap-x-6">
            <MainButton
              label="Contact Us"
              destination={urls.contact}
              type={ButtonTypes.secondary}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const ImgHolder: FC<{ src: string; alt: string; width?: number }> = ({
  src,
  alt,
  width,
}) => {
  return (
    <div className="p-6 bg-gray-100/2.5 sm:p-10">
      <img
        className="object-contain w-full max-h-12"
        src={src}
        alt={alt}
        width={width ?? 158}
        height={48}
      />
    </div>
  );
};

/** grid items-center grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2 */
