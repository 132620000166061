import { MainButton } from "./MainButtons";
import { urls } from "../routes/RouteDefinitions";

export const MainHero = () => {
  return (
    <div className="relative isolate -z-10">
      <div
        className="absolute top-0 right-0 -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
        aria-hidden="true"
      >
        <div
          className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
          }}
        />
      </div>
      <div className="overflow-hidden">
        <div className="px-6 pb-32 mx-auto max-w-7xl pt-36 sm:pt-60 lg:px-8 lg:pt-32">
          <div className="max-w-2xl mx-auto gap-x-14 lg:mx-0 lg:flex lg:max-w-7xl lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-25 sm:text-7xl">
                Driving your Business Forward.
              </h1>
              <p className="relative my-6 text-lg leading-8 text-gray-400 sm:max-w-md lg:max-w-none">
                Experience a paradigm shift as we skillfully propel your
                enterprise forward, setting a new standard for success in the
                ever-evolving business landscape. Let's partner together to
                elevate your business!
              </p>
              <MainButton
                label="Let's Build Together"
                destination={urls.contact}
              />
            </div>
            <div className="flex justify-end gap-8 mt-14 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
              <div className="flex-none pt-32 ml-auto space-y-8 w-44 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                <div className="relative">
                  <img
                    src="img4.jpg"
                    alt=""
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              <div className="flex-none mr-auto space-y-8 w-44 sm:mr-0 sm:pt-52 lg:pt-36">
                <div className="relative">
                  <img
                    src="img10.jpg"
                    alt=""
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative">
                  <img
                    src="img5.jpg"
                    alt=""
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              <div className="flex-none pt-32 space-y-8 w-44 sm:pt-0">
                <div className="relative">
                  <img
                    src="img2.jpg"
                    alt=""
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative">
                  <img
                    src="img7.jpg"
                    alt=""
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AltMainHero = () => {
  return (
    <>
      <div className="mx-auto max-w-7xl">
        <div className="relative bg-black h-[600px] items-center">
          <div className="col-span-2 bg-[url('https://source.unsplash.com/PhYq704ffdA')] bg-cover bg-center">
            <div className="flex items-center justify-center w-full h-full backdrop-brightness-50">
              {/* <span className="w-1/2 text-4xl text-center text-white">
        Here is an example of black overlay on an image
      </span> */}
            </div>
          </div>
          <div className="absolute z-10 ml-24 top-12">
            <h2 className="text-6xl font-bold tracking-tight text-gray-25 sm:text-7xl">
              Your technology partner is just one click away
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
