import { FC, Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { classNames } from "../utilities/helpers";
// import { logo } from "./logo.png";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { urls } from "../routes/RouteDefinitions";
import { useLocation } from "react-router-dom";
import { ButtonTypes, MainButton } from "./MainButtons";

const products = [
  {
    name: "Application Development",
    description: "Get a better understanding of your traffic",
    href: "#application-development",
    icon: ChartPieIcon,
  },
  {
    name: "System Integration",
    description: "Speak directly to your customers",
    href: "#system-integration",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Portals & Collaboration",
    description: "Your customers’ data will be safe and secure",
    href: "#portals-collaboration",
    icon: FingerPrintIcon,
  },
  {
    name: "Mobile Application Development",
    description: "Connect with third-party tools",
    href: "#mobile-application-development",
    icon: SquaresPlusIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

const MainNavigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const showAsDark = () => {
    if (location.pathname.includes("contact")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <header
      className={classNames(
        showAsDark() ? "bg-alto shadow-sm" : "bg-black",
        "sticky top-0 z-40 w-full "
      )}
    >
      <nav
        className={classNames(
          showAsDark() ? "bg-alto" : "bg-black",
          "flex items-center justify-between p-3 mx-auto  max-w-7xl lg:px-8"
        )}
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a
            href={urls.home}
            className="-m-1.5 p-1.5"
          >
            <span className="sr-only">Your Company</span>
            <img
              src={showAsDark() ? "./qcLogoFull-dark.svg" : "./qcLogoFull.svg"}
              alt="home"
              width={"114.75"}
              height={"24"}
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon
              className="w-6 h-6 text-gray-25"
              aria-hidden="true"
            />
          </button>
        </div>
        <Popover.Group className="items-center hidden lg:flex lg:gap-x-8">
          <NavButton
            title="About Us"
            destination={urls.aboutUs}
          />
          <NavButton
            title="Our Services"
            destination={urls.services}
          />
          <a
            href="https://www.hr-flex.com"
            className={classNames(
              "leading-6 text-gray-25",
              "text-sm font-semibold"
            )}
          >
            {"HRFlex™"}
          </a>

          <a
            href={urls.contact}
            className={classNames(
              showAsDark()
                ? "text-gray-100 bg-black"
                : "text-gray-900 bg-white",
              "px-6 py-2 text-sm font-semibold leading-6 rounded-sm hover:text-primary-900"
            )}
          >
            Get Started
          </a>
        </Popover.Group>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 pt-24" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 overflow-y-auto bg-alto top-12 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flow-root mt-6">
            <div className="-my-6 text-center divide-y divide-gray-500/10">
              <div className="space-y-2">
                <a
                  href={urls.aboutUs}
                  onClick={() => setMobileMenuOpen(false)}
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                >
                  About Us
                </a>
                <a
                  href={urls.services}
                  onClick={() => setMobileMenuOpen(false)}
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                >
                  Our Services
                </a>
                <a
                  href={urls.contact}
                  onClick={() => setMobileMenuOpen(false)}
                  className="block px-3 py-2 -mx-3 text-sm font-semibold leading-7 bg-black rounded-lg text-gray-25 hover:bg-gray-500"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

interface NavDestination {
  title: string;
  destination: string;
  dark?: boolean;
}
const NavButton: FC<NavDestination> = (props) => {
  return (
    <a
      href={props.destination}
      className={classNames(
        !props.dark ? "leading-6 text-gray-900" : "leading-6 text-gray-25",
        "text-sm font-semibold"
      )}
    >
      {props.title}
    </a>
  );
};

NavButton.defaultProps = {
  dark: true,
};
export default MainNavigation;
